import { z } from 'zod';

export const SlideScanId = z.string().min(1).max(100).describe('The ID of the slide-scan');

export const TenantId = z.string().min(1).describe('The tenant id');
export type TenantIdType = z.infer<typeof TenantId>;

const slideScanVerifiedArr = ['CONFIRMED', 'RESCAN_REQUESTED', 'RESCANNED'] as const;
const slideScanUnverifiedArr = ['AI_PROCESSED'] as const;
export const SlideScanVerifiedStatus = z.enum(slideScanVerifiedArr);
export const SlideScanUnverifiedStatus = z.enum(slideScanUnverifiedArr);
export const SlideScanStatus = z.enum([...slideScanVerifiedArr, ...slideScanUnverifiedArr]);
export type SlideScanStatusType = z.infer<typeof SlideScanStatus>;

export const CONSTANTS = {
  MAX_PAGE_SIZE: 1000,
  DEFAULT_PAGE_SIZE: 100,
};

export const DATE_HELPERS = {
  getDefaultStartDate: () => new Date(0),
  getDateNow: () => new Date(),
};

export const KNOWN_FILE_NAMES = {
  metadata: 'metadata.json',
  aiResults: 'result.json',
  aiLogs: 'logs.txt',
} as const satisfies Record<string, string>;

export type KnownFiles = typeof KNOWN_FILE_NAMES[keyof typeof KNOWN_FILE_NAMES];

export const configState = z.enum(['CREATED', 'ONBOARDED']);

export type ConfigStateType = z.infer<typeof configState>;

export const AIDetectableSlideScanIssue = z.enum(['TISSUE_NOT_DETECTED', 'OUT_OF_FOCUS_REGION', 'FOLDS']);

export type AIDetectableSlideScanIssueType = z.infer<typeof AIDetectableSlideScanIssue>;

export const SlideScanIssue = z.enum([
  ...AIDetectableSlideScanIssue.options,
  'TISSUE_OUTSIDE_MAXIMUM_SCANNING_AREA',
  'TISSUE_OUTSIDE_COVER_AREA',
  'MARKER',
  'STRIPES',
  'MISALIGNED_IMAGE_STITCHING',
  'AIR_BUBBLES',
  'THICKNESS_VARIATION',
  'STAINING_INHOMOGENEITY',
  'FOREIGN_MATERIAL',
  'OTHER',
]);
export type SlideScanIssueType = z.infer<typeof SlideScanIssue>;

export const SlideScanVerifiedIssueStatus = z.enum([
  'FALSE_POSITIVE', // Issue was detected by AI, but rejected by user
  'FALSE_NEGATIVE', // Issue was not detected by AI, but added by user
  'ACCEPTED', // Issue was detected by AI and accepted by user
  'USER_ONLY', // Issue was added by user, but AI is not configured for this type
]);
export type SlideScanVerifiedIssueStatusType = z.infer<typeof SlideScanVerifiedIssueStatus>;

export const SlideScanDetectedIssue = z.object({
  slideScanId: SlideScanId.optional(),
  issueType: SlideScanIssue,
  isAIDetected: z.boolean(),
  isUserDetected: z.boolean(),
  aiIssueStatus: z.enum(['ACCEPTED', 'REJECTED']).optional(),
});
export type SlideScanDetectedIssue = z.infer<typeof SlideScanDetectedIssue>;

export const SlideScanItem = z.object({
  id: SlideScanId,
  tileServerUri: z.string().optional().describe('The URI needed for calls to the tileserver'),
  triggeredAt: z
    .string()
    .datetime({ offset: true })
    .optional()
    .describe('Date, time and zone the slide scan was triggered for processing'),
  detectedIssues: z.array(SlideScanDetectedIssue).optional().default([]).describe('Detected issues for slide scan'),
  verified: z.boolean().optional().default(false).describe('Indicates if the slide scan has been verified or not'),
  wsiThumbnail: z
    .object({
      name: z.string().describe('The filename of the thumbnail, relatively to the path of the slide-scan.'),
      url: z.string().describe('The location of the wsi-thumbnail').optional(),
    })
    .optional()
    .describe('The WSI thumbnail that has been detected in the slide-scan'),
  macro: z
    .object({
      name: z.string().describe('The filename of the macro, relatively to the path of the slide-scan.'),
      url: z.string().describe('The location of the wsi-thumbnail').optional(),
    })
    .optional()
    .describe('The macro that has been detected in the slide-scan'),
  label: z
    .object({
      name: z.string().describe('The filename of the label, relatively to the path of the slide-scan.'),
      url: z.string().describe('The location of the label').optional(),
    })
    .optional()
    .describe('The label that have been detected in the slide-scan'),
  patches: z
    .array(
      z.object({
        name: z.string().describe('The filename of the patch, relatively to the path of the slide-scan.'),
        url: z.string().describe('The location of the patch').optional(),
      }),
    )
    .length(5)
    .optional()
    .describe('The list of AIPatchIssueOutFocusRegion patches'),
  scanner: z
    .object({
      type: z.string().max(1000).optional().describe('Type of scanner'),
      serialNumber: z.string().max(1000).optional().describe('Serial number of the scanner'),
    })
    .optional()
    .describe('Scanner information'),
  rackNumber: z.number().nonnegative().optional().describe('Rack number'),
  rackPosition: z.number().nonnegative().optional().describe('Rack position'),
  staining: z.string().optional().describe('Staining type of the slide'),
  customField1: z.string().optional().describe('Value for custom field 1'),
  aiResultUrl: z.string().optional().describe('The location of the ai results'),
  magnification: z.number().nonnegative().optional().describe('The magnification factor of the scan'),
  processingState: SlideScanStatus.describe('The processing state of the slide scan'),
  lastUpdatedBy: z.string().optional(),
  aiProcessingStatus: z
    .enum(['SUCCESS', 'FAILED'])
    .describe('The status of the AI output, if it was successful or not'),
  isRescan: z
    .boolean()
    .default(false)
    .describe('Indication of whether this slide scan is a rescan of another one'),
  caseName: z.string().describe('the name of the case'),
  slideName: z.string().describe('the name of the slide'),
  slideScanDisplayName: z.string().optional().describe('The display name of the slide scan'),
});

export type SlideScanItemType = z.infer<typeof SlideScanItem>;

export const DataplaneId = z.string().uuid().describe('Dataplane id');
export const AIPatchIssueOutFocusRegionPatches = [
  'patch_1.png',
  'patch_2.png',
  'patch_3.png',
  'patch_4.png',
  'patch_5.png',
];

export const AWSCredentials = z.object({
  accessKey: z.string().describe('The access key for signing the urls'),
  secretAccessKey: z.string().describe('The secret access key for signing the urls'),
  sessionToken: z.string().describe('The session token for signing the urls'),
});

export type AWSCredentials = z.infer<typeof AWSCredentials>;

export const BucketCredentials = AWSCredentials.extend({
  bucketName: z.string().describe('The name of the bucket that should be used to sign the urls'),
});

export type BucketCredentials = z.infer<typeof BucketCredentials>;

export const BucketCredentialsWithKey = BucketCredentials.extend({
  key: z.string().describe('The key of the file in the bucket'),
});

export type BucketCredentialsWithKey = z.infer<typeof BucketCredentialsWithKey>;

export const AWSRumCredentials = AWSCredentials.extend({
  appMonitorId: z.string().describe('The app monitor id for the RUM'),
});

export type AWSRumCredentials = z.infer<typeof AWSRumCredentials>;
