import type { ResponseConfig } from '@asteasolutions/zod-to-openapi';

export const error400: ResponseConfig = {
  description: 'The request is badly formatted',
  content: {
    'application/json': {
      schema: {
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A more detailed error message',
          },
        },
      },
    },
  },
};

export const error401: ResponseConfig = {
  description: 'The request is unauthorized',
  content: {
    'application/json': {
      schema: {
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A more detailed error message',
          },
        },
      },
    },
  },
};

export const error403: ResponseConfig = {
  description: 'The request is forbidden',
  content: {
    'application/json': {
      schema: {
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A more detailed error message',
          },
        },
      },
    },
  },
};

export const error500: ResponseConfig = {
  description: 'An internal server error occurred',
  content: {
    'application/json': {
      schema: {
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A more detailed error message',
          },
        },
      },
    },
  },
};

export const defaults = {
  400: error400,
  401: error401,
  403: error403,
  500: error500,
};
