import { z } from 'zod';
import { areIntervalsOverlapping } from 'date-fns';
import { TenantId, AIDetectableSlideScanIssue } from './shared';

export const TenantFrontendConfigSchema = z
  .object({
    version: z.string().min(1).describe('The version of the frontend tenant configuration'),
    tileServerUrl: z.string().url().describe('The tile server URL.').optional(), // When tenant is created, tile server url is unknown
    timezone: z
      .string()
      .optional()
      .default(() => new Intl.DateTimeFormat().resolvedOptions().timeZone)
      .refine((value) => value === 'UTC' || Intl.supportedValuesOf('timeZone').includes(value), {
        message: 'must be a supported timezone',
      })
      .describe('The timezone of the tenant'),
    customFields: z
      .record(z.enum(['customField1', 'customField2', 'customField3']), z.object({ displayName: z.string() })).default({}),
  })
  .describe('The tenant-controlled frontend settings.');

export const TenantDataplaneConfigSchema = z
  .object({
    version: z.string().min(1).describe('The version of the data plane tenant configuration'),
    triggers: z
      .object({
        inputTrigger: z.string().describe('The input trigger type for the dataplane (e.g., FILE_WATCHER)'),
        pathToWatch: z.string().describe('The path (or equivalent setting) for this input trigger.'),
      })
      .describe('A single trigger specification for events on the data plane.')
      .array()
      .describe('List of triggers for the data plane.'),
    bootConfig: z
      .object({
        logLevel: z.string().default('info').describe('Log level for the data plane appliance'),
      })
      .describe('The tenant-controller boot config for the data plane.'),
    anonymizationSettings: z
      .object({
        enabled: z
          .boolean()
          .default(false)
          .describe('Flag to indicate whether or not anonymization is enabled for this tenant.'),
        macroMask: z.number().int().nonnegative().describe('The size of the mask to apply'),
      })
      .describe('The tenant-controlled anonymization settings.'),
  })
  .describe('The tenant controlled dataplane settings');

const coerceInterval = ({ start, end }: { start: string; end: string }) =>
  ({ start: new Date(start), end: new Date(end) });

export const TenantLicenseConfigSchema = z.object({
  version: z.string().min(1).describe('The version of the tenant license configuration'),
  allowances: z
    .array(
      z.object({
        period: z.object({
          start: z
            .string()
            .datetime({ offset: true })
            .default(new Date().toISOString())
            .describe('Timestamp when the period starts'),
          end: z
            .string()
            .datetime({ offset: true })
            .default(new Date().toISOString())
            .describe('Timestamp when the period ends'),
        }),
        global: z
          .number()
          .int()
          .nonnegative()
          .finite()
          .describe('The max number of total slidescans to anonymize for this period'),
        falsePositives: z
          .number()
          .int()
          .nonnegative()
          .finite()
          .describe('The max number of total false positive slidescans to anonymize for this period'),
        falseNegatives: z.array(
          z.object({
            type: AIDetectableSlideScanIssue,
            allowance: z
              .number()
              .int()
              .nonnegative()
              .finite()
              .describe('The max number of total false negative slidescans for this type to anonymize for this period'),
          }),
        ),
        manuallyAnnotated: z
          .number()
          .int()
          .nonnegative()
          .finite()
          .describe(
            'The max number of total slides to anonymize for this period with at least one USER_ONLY issue annotation',
          ),
      }),
    )
    .superRefine((allowances, ctx) => {
      if (
        allowances
          .map(({ period }) => period)
          .some((interval, idx, arr) =>
            arr.slice(idx + 1).some(
              (interval2) => areIntervalsOverlapping(coerceInterval(interval), coerceInterval(interval2)),
            ),
          )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'allowance periods cannot overlap',
        });
      }
    }),
  daily: z.object({
    aiFailures: z
      .number()
      .int()
      .nonnegative()
      .finite()
      .describe('The max number of total AI failure slides to anonymize each day'),
  }),
});

export type TenantFrontendConfigType = z.infer<typeof TenantFrontendConfigSchema>;
export type TenantDataplaneConfigType = z.infer<typeof TenantDataplaneConfigSchema>;
export type TenantLicenseConfigType = z.infer<typeof TenantLicenseConfigSchema>;

// Tenant id will be added from the api context. It will not be stored in the database.
export const TenantFrontendConfigResponseSchema = TenantFrontendConfigSchema.extend({
  tenantId: TenantId,
  displayName: z.string().optional().describe('The display name of the tenant'),
});

export type TenantFrontendConfigResponseType = z.infer<typeof TenantFrontendConfigResponseSchema>;

export const TenantDataplaneConfigResponseSchema = TenantDataplaneConfigSchema.extend({
  tenantId: TenantId,
});
export type TenantDataplaneConfigResponseType = z.infer<typeof TenantDataplaneConfigResponseSchema>;
